
html, body {
  /* background: white; */
}
@font-face {
  font-family: 'Yokai';
  src: url('./fonts/Yokai.otf') format("opentype");
}
@font-face {
  font-family: 'Gaylon';
  src: url('./fonts/Galyon-Book.otf') format("opentype");
}
.page-wrap {
  display: flex;
  justify-content: center;
}

.content-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  max-width: 50%;
  max-height: 100%;
  z-index: 10;
}

.center-box {
  padding: 40px;
  background: #245a36;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(16, 85, 52, 0.9), rgba(16, 85, 52, 0.9)), url("https://uploads-ssl.webflow.com/614c8eb354dc037fcbe67dc9/614cd9f6c08eaa47dbd18527_Tiger1-min.jpg");
  width: 70%;
  margin-bottom: 60px;
}

.above-chart {
  width: 100%;
}

@media (max-width: 1200px) {
  .content-wrap {
    max-width: 90%;
  }

  .battle-text {
    font-size: 18px !important;
  }
}

/* 
@media (max-width: 768px) {
  .center-box {
    padding: 20px;
    width: 90%;
    min-width: 400px;
  }
}
*/

.tribes-logo {
  max-width: 300px;
}

.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.legend-item {
  margin: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .legend-item {
    width: 160px;
  }
}

@media (min-width: 769px) {
  .legend-item {
    width: 170px;
  }
}

.legend-color {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 20px;
}

.legend-label {
  color: white;
  font-family: 'Gaylon';
}

.time-update-label {
  color: white;
  font-family: 'Gaylon';
}

.chart-title {
  font-family: 'Yokai';
  color: #e9931b;
  font-size: 50px;
}

.bg-vid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .tribes-logo {
    max-width: 250px;
  }
  .chart-title {
    font-size: 30px;
  }
  .center-box {
    padding: 20px;
  }
}

.nav-button {
  background-color: #245a36;
  border: 1px solid #e9931b;
  color: #e9931b;
  font-size: 25px;
  font-weight: 550;
  border-radius: 5px;
  margin: 10px;
  font-family: 'Yokai';
  width: 200px;
}

.nav-button:hover {
  cursor: pointer;
  background-color: #aaaaaa;
  color: black;
}

.navigation-menu {
  margin-bottom: 20px;
}

.section-text {
  color: white;
  font-family: 'Gaylon';
}

.battle-text {
  font-family: 'Gaylon';
  font-size: 22px;
  display: inline-block;
  line-height: 1.4;
  color: white;
}

.battle-text a { 
  text-decoration: underline;
  color: white;
}

.snowflakes {
  z-index: 1;
  display: none;
}

@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}
@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}

.snowflakesmobile2 {
  position: absolute;
  right: 10px;
}

.snowflakesmobile {
  position: absolute;
  left: 10px;
}

.snowflake2 {
  overflow: hidden;
}

@media (min-width: 768px) {
  .snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}
  .snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}
  .snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}
  .snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}
  .snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}
  .snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}
  .snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}
  .snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}
  .snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}
  .snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}
  .snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}

  .snowflakesmobile2, .snowflakesmobile {
    display: none;
  }

  .snowflakes {
    display: block;
  }
}

.tribe-leaders .section-text{
  width: 100%;
  margin: auto;
  word-break: break-all;
}

.tribe-leaders h2 {
  font-family: 'Yokai';
  color: #e9931b;
  font-weight: 550;
  font-size: 30px;
}

.section-text a {
  color: white;
}

.battletext-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mint-now {
  display: block;
  min-width: 100%;
  /* min-width: 200px; */
  font-size: 36px;
  /* margin: 10px 40px */
  padding: 0 10px;
}

.no-under {
  text-decoration: none;
  margin: 10px 80px;
}

.no-margin {
  margin-bottom: 10px;
}

.timer-time:not(.timer-word) {
  font-weight: 700;
}